.footer {
    background: #5B5B5B;
    height: 10vh;
    border-bottom: 5px solid #737373;
    padding: 20px 0 30px;
    color: white;
    text-align: center;
}
.footerLink {
    color: white;
}
.errorText {
    color: red;
    margin: 2px
}
.signInButton {
    width: 100%;
}
.hrCustom {
    border-top: 1px solid white;
}
.wrapper {
    background-color: #460073;
    display: flex;
    height: 90vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-image:
        url('../assets/imgs/BKG-Lines-Light-White.png');
    color: white;
}
.loginForm {
    margin-top: 5%;
    display: block;
    border: 3px solid #ffffff;
    margin: auto;
    padding: 32px 32px;
    align-items: center;
    justify-items: center;
    width: auto;
    min-width: 40vh;
}
.center {
    text-align: center;
}
.description {
    font-weight: bold; 
    font-size: 16;
}
.providerWrapper {
    padding-top: 16px;
}
.providerBtn {
    width: 100%;
    background-color: #008eff;
    border-color: #008eff;
}
.spinnerLocation {
    margin-top: 15vh;
}